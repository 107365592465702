<template>
  <div>
    <div class="notfound">
        <img src="../assets/imgs/uploads/Group 507.png" class="img" alt="notfound">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.notfound{
    width: 506px;
    margin: 176px auto 202px;
}
.img{
    width: 506px;
    height: 539px;
}
</style>